import { useRouter } from "next/router";

export const Preview = () => {
    const { query } = useRouter();

    return query.previewPb ? (
        <div className="fixed p-2 bg-blue-500 text-white rounded bottom-4 left-4">
            You are in preview mode
        </div>
    ) : null;
};
